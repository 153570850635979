export type AppConfig = {
    apiPrefix: string
    authenticatedEntryPath: string
    unAuthenticatedEntryPath: string
    tourPath: string
    locale: string
    enableMock: boolean
    marketApiPrefix: string
}

const appConfig: AppConfig = {
    apiPrefix: '',
    authenticatedEntryPath: '/emission-input',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    marketApiPrefix: 
    import.meta.env.VITE_ENVIRONMENT?.trim().toLowerCase() === "development"
      ? "https://marketplace.untrash.earth/"
      : import.meta.env.VITE_ENVIRONMENT?.trim().toLowerCase() === "local"
      ? "http://localhost:3000/"
      : import.meta.env.VITE_ENVIRONMENT?.trim().toLowerCase() === "production"
      ? "https://untrash.earth/":""
  }


export default appConfig
