import { FinalProductData, LcaData, ManufacturingPlantData, PackagingMaterialData, ProcessData, ProductionQuantityData, RawMaterialData, TotalWaste, WasteData } from "@/@types/lca";
import ProductionQuantity from "@/views/components/ProductLCA/ProductionQuantity";
import { createSlice } from "@reduxjs/toolkit";

export interface LcaState {
    lcaDetails:LcaData;
    manufacturingPlant: ManufacturingPlantData[];
    finalProduct: FinalProductData[];
    rawMaterial: RawMaterialData[];
    packagingMaterial: PackagingMaterialData[];
    process: ProcessData[],
    waste: WasteData,
    productionQuantity: ProductionQuantityData[]
}

const initialState: LcaState =  {
        lcaDetails:{id:null, name:""},
        manufacturingPlant: [],
        finalProduct: [],
        rawMaterial:[],
        packagingMaterial:[],
        process: [],
        waste: {
            totalwaste: {} as TotalWaste,
            checked: false,
            processWastes: []
        },
        productionQuantity: []
    }

const lcaSlice = createSlice({
    name: "lca",
    initialState: initialState,
    reducers: {
        addLca:(state, action)=>{
            state.lcaDetails=(action.payload)
        },
        clearLca: (state) => {
            state.lcaDetails = {id:null, name:""}
        },
        addManufacturingPlant: (state, action) => {
            state.manufacturingPlant.push(action.payload)
        },
        updateManufacturingPlant: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.manufacturingPlant = state.manufacturingPlant.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                return product
            })
        },
        removeManufacturingPlant: (state, action) => {
            state.manufacturingPlant = state.manufacturingPlant.filter((item) => item.name !== action.payload.name)
        },
        addFinalProduct: (state, action) => {
            state.finalProduct.push(action.payload)
        },
        removeFinalProduct: (state, action) => {
            state.finalProduct = state.finalProduct.filter((item) => item.name !== action.payload.name)
        },
        updateFinalProduct: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.finalProduct = state.finalProduct.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                return product
            })
        },
        updateFinalProductCheck: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.finalProduct = state.finalProduct.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                return product
            })
        },
        addRawMaterial: (state, action) => {
            state.rawMaterial.push(action.payload)
        },
        // removeRawMaterial: (state, action) => {
        //     state.rawMaterial = state.rawMaterial.filter((item) => (item.name !== action.payload.name || item.supplierAddress!==action.payload.supplierAddress))
        // },
        removeRawMaterial: (state, action) => {
            console.log(action.payload.index)
            state.rawMaterial = state.rawMaterial.filter((_, index) => index !== action.payload.index);
        },
        updateRawMaterial: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.rawMaterial = state.rawMaterial.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                // const check = state.rawMaterial.some((item) => 
                //     item.name === product.name && item[columnId] === value
                // );
                // if (!check) {
                //     return { ...product, [columnId]: value };
                // }
                return product
            })
        },
        addPackagingMaterial: (state, action) => {
            state.packagingMaterial.push(action.payload)
        },
        removePackagingMaterial: (state, action) => {
            console.log(action.payload.index)
            state.packagingMaterial = state.packagingMaterial.filter((_, index) => index !== action.payload.index);
        },
        removePackagingMaterialByFinalProductId: (state, action) => {
            state.packagingMaterial = state.packagingMaterial.filter((item) => item?.finalMaterialId !== action.payload);
        },
        updatePackagingMaterial: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.packagingMaterial = state.packagingMaterial.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                // const check = state.rawMaterial.some((item) => 
                //     item.name === product.name && item[columnId] === value
                // );
                // if (!check) {
                //     return { ...product, [columnId]: value };
                // }
                return product
            })
        },
        updatePackagingMaterialByFinalProductId: (state, action) => {
            const {finalId, column, value} = action.payload
            console.log(action.payload)
            console.log(state.packagingMaterial)
            console.log("comssspq")
            state.packagingMaterial = state.packagingMaterial?.map((product) => {
                console.log("compq")
                if (product?.finalMaterialId==finalId) {
                    console.log("ss", finalId)
                    return {...product, [column]: value}
                }
                // const check = state.rawMaterial.some((item) => 
                //     item.name === product.name && item[columnId] === value
                // );
                // if (!check) {
                //     return { ...product, [columnId]: value };
                // }
                console.log("comp")
                return product
            })
        },       
        addProcess: (state, action) => {
            state.process.push(action.payload)
        },
        removeProcess: (state, action) => {
            state.process = state.process.filter((item) => item.name !== action.payload.name)
        },
        updateProcess: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.process = state.process.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                return product
            })
        },
        updateTotalWaste: (state, action) => {
            const {columnId, value} = action.payload
            state.waste.totalwaste = {...state.waste.totalwaste, [columnId]: value}
        },
        setChecked: (state, action) => {
            state.waste.checked = action.payload
        },

        createProcessWastes: (state) => {
            const processList = state.waste.processWastes.map((p) => p.process);
            const newWasteList = state.process.filter((item) => !processList.includes(item.name));
            const data = newWasteList.map((p) => ({
                processId: p.id,
                process: p.name,
                fuelConsumed: '',
                electricity: '',
                water: '',
                solidWaste: '',
                liquidWaste: ''
            }));
            state.waste.processWastes = [...state.waste.processWastes, ...data];
        },
        
        updateProcessWaste: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.waste.processWastes = state.waste.processWastes.map((p, index) => {
                if (index === rowIndex) {
                    return {...p, [columnId]: value}
                }
                return p
            })
        },
        removeProductionWaste: (state, action) => {
            console.log("started")
            state.waste.processWastes = state.waste.processWastes.filter((item) => item.processId !== action.payload.id)
        },
        // addProductionQuantity: (state, action) => {
        //     const list = state.productionQuantity.map(product=>product.name)
        //     if(!list.includes(action.payload.name)){
        //         state.productionQuantity.push(action.payload)
        //     }
        // },
        // removeProductionQuantity: (state, action) => {
        //     console.log("started", action.payload.name)
        //     state.productionQuantity = state.productionQuantity.filter((item) => item.name !== action.payload.name)
        // },
        // updateProductionQuantity: (state, action) => {
        //     const {rowIndex, columnId, value} = action.payload
        //     state.productionQuantity = state.productionQuantity.map((product, index) => {
        //         if (index === rowIndex) {
        //             return {...product, [columnId]: value}
        //         }
        //         return product
        //     })
        // },
        clearProductionQuantity: (state) => {
            state.productionQuantity = []
        },
        clearProcessWastes: (state) => {
            state.waste.processWastes = []
        },
        clearLcaSlice: () => initialState
    },
})

export const {
                addLca,
                clearLca,
               addManufacturingPlant,
               updateManufacturingPlant,
               removeManufacturingPlant, 
               addFinalProduct, 
               removeFinalProduct, 
               updateFinalProduct,
               updateFinalProductCheck,
               addRawMaterial, 
               removeRawMaterial, 
               updateRawMaterial,
               addPackagingMaterial,
               removePackagingMaterial,
               updatePackagingMaterial,
               addProcess,
               removeProcess, 
               updateProcess,
               updateTotalWaste, 
               setChecked,
               createProcessWastes,
               updateProcessWaste,
               removeProductionWaste,
               addProductionQuantity,
               removeProductionQuantity,
               updateProductionQuantity,
               removePackagingMaterialByFinalProductId,
               updatePackagingMaterialByFinalProductId,
               clearProductionQuantity,
               clearProcessWastes,
               clearLcaSlice
            } = lcaSlice.actions
export default lcaSlice.reducer